<template>
<div></div>
</template>
<script>
import {mapGetters} from "vuex";
import eventBus from "@/eventBus";

export default {
  name: "Socket",
  props: ['isNeedAnyAuthActions'],
  data() {
    return {
      socket: null,
      eventData: {},
      reconnected: false,
      reconnectInterval: 5000,
    }
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  watch: {
    isNeedAnyAuthActions(newVal) {
      if (!newVal) {
        if(!this.socket) {
          this.socket_init()
        }
      } else {
        if(this.socket) {
          this.socket.close(1000)
          this.socket = null; // to prevent memory leacking
        }
      }
    }
  },
  methods: {
    socket_init() {
      if (this.userId == 0 || this.userId == "" || this.userId == undefined) {
        return
      }
      this.socket = new WebSocket("/eapi/nbnh-dealers-backend-app/ws/" + this.userId)
      this.socket.onmessage = this.socket_message
      this.socket.onopen = this.socket_open
      this.socket.onclose = this.socket_close
      this.socket.onerror = this.socket_error
    },
    socket_open() {
      console.log("websocket connected")
    },
    socket_message(event) {
      this.eventData = JSON.parse(event.data)
      this.notifyStatus()
    },
    socket_close(event) {
      console.log(event.code)
      switch(event.code) {
        case 1000:
          console.log("websocket closed")
          break;
        default:
          this.socket_reconnect(event)
          break
      }
    },
    socket_error(event) {
      console.log("websocket error", event.code)
      switch(event.code) {
        case 'ECONNREFUSED':
        case 'ECONNRESET':
          this.socket_reconnect(event)
          break;
        default:
          break
      }
    },
    socket_reconnect() {
      let that = this
      setTimeout(function() {
        that.reconnected = true
        that.socket_init()
      }, this.reconnectInterval)
    },
    notifyStatus() {
      eventBus.$emit('custom-event', this.eventData)
      this.$store.commit('user/SET_HAS_NOTIFICATIONS')
    }
  },
  mounted() {
    if(!this.isNeedAnyAuthActions) {
      this.socket_init()
    }
  }
}
</script>